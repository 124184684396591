<template>
  <div>
    <!-- 會員登入 -->
    <div class="member_main">
      <div class="member_main_container">
        <div class="member_login_contianer">
          <div>
            <div class="member_login_choice_container">
              <div @click="loginId=0" class="member_login_choice" :class="{'active':loginId==0}">
                <span>學生登入</span>
              </div>
              <div @click="loginId=1" class="member_login_choice" :class="{'active':loginId==1}">
                <span>教師登入</span>
              </div>
            </div>
          </div>
          <div style="padding:0 30px 50px 30px" class="s_login" v-if="loginId==0">
            <div class="member_login_input">
              <input
                class="account_icon"
                v-model="userMessage.username"
                placeholder="學生會員手機號碼"
                type="text"
              />
            </div>
            <div class="member_login_input">
              <input
                class="password_icon"
                v-model="userMessage.password"
                placeholder="學生會員密碼"
                type="password"
              />
              <div class="password_get_container" style="color:#ffffff">
                <span class="password_get_open" @click="forgetPassword">忘記密碼？</span>
              </div>
            </div>
            <div class="member_login_btn" @click="toLogin">學生登入</div>
            <div class="member_keep_login">
              <input type="checkbox" v-model="userMessage.isKeepLogin" />
              <label for="keep_login">保持登入狀態</label>
            </div>
          </div>
          <div style="padding:0 30px 50px 30px" class="t_login" v-if="loginId==1">
            <div class="member_login_input">
              <input
                class="account_icon"
                v-model="teacherMessage.username"
                placeholder="教師會員號碼"
                type="text"
              />
            </div>
            <div class="member_login_input">
              <input
                class="password_icon"
                v-model="teacherMessage.password"
                placeholder="教師會員密碼"
                type="password"
              />
              <!-- <div class="password_get_container" style="color:#ffffff">
                <span class="password_get_open">忘記密碼？</span>
              </div>-->
            </div>
            <div class="member_login_btn" @click="toLoginTeacher">教師登入</div>
            <div class="member_keep_login">
              <input type="checkbox" v-model="teacherMessage.isKeepLogin" />
              <label for="t_keep_login">保持登入狀態</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 會員登入end -->
    <!--注册弹窗-->
    <register-dialog :registerDialog="registerStep" @changeStep="setRegisterStep"></register-dialog>

    <!-- ... 重新設定密碼-身分驗證 ... -->
    <div class="pup_container_form get_password_step_1" v-if="resetDialog">
      <div class="pup_form_bg" @click="resetDialog=false"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">重新設定密碼</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div>
              <div class="form_input_title">會員註冊手機號碼</div>
              <div class="form_input">
                <input placeholder v-model="resetPassword.phoneNum" type="text" />
              </div>
            </div>
          </div>
          <div class="pup_form_tip">將傳送簡訊驗證碼確認會員身分</div>
        </div>
        <img
          alt
          class="close_icon"
          @click="closeDialog('reset')"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn get_password_step_1_send" @click="sendPassword('5')">確認送出</div>
      </div>
    </div>
    <!-- ... 重新設定密碼-身分驗證end ... -->
    <!-- ... 重新設定密碼 ... -->
    <div class="pup_container_form get_password_step_2" v-if="showInputPassword">
      <div class="pup_form_bg" @click="closeDialog('showInputPassword')"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">重新設定密碼</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div class="form_input_title">設定密碼</div>
            <div class="form_input">
              <input placeholder v-model="resetPassword.password" type="password" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">再次輸入確定密碼</div>
            <div class="form_input">
              <input placeholder type="password" v-model="surePassword" />
            </div>
            <div class="form_input_tip" v-if="errTips">{{errTips}}</div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">簡訊驗證碼</div>
            <div class="form_input">
              <input v-model="resetPassword.code" placeholder type="text" />
            </div>
          </div>
          <div class="pup_form_tip">
            沒有收到簡訊？
            <a @click="getSmsCode('5')">重新發送</a>
          </div>
        </div>
        <img
          alt
          class="close_icon"
          @click="closeDialog('showInputPassword')"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn get_password_step_2_send" @click="sendSmscode">確認送出</div>
      </div>
    </div>
    <!-- ... 重新設定密碼end ... -->
    <!-- ... 設定密碼成功 ... -->
    <div class="pup_container_form get_password_step_3" v-if="resetSuccess">
      <div class="pup_form_bg" @click="resetSuccess=false"></div>
      <div class="pup_form_container">
        <div class="pup_form_text">密碼設定成功</div>
        <div class="pup_form_text_tip">請以新的密碼作會員登入</div>
        <div class="pup_form_btn get_password_step_3_send" @click="closeDialog('resetSuccess')">關閉</div>
      </div>
    </div>
    <!-- ... 設定密碼成功end ... -->
    <!-- ... pup視窗用 ... -->
  </div>
</template>
<script>
import {
  getHsdForgetPassword,
  getHsdUserLogin,
  getHsdSmsCode,
  getTeacherLogin
} from "../../api/api";
import hsdHeader from "../layout/headerHsd";
import registerDialog from "../childComponent/registerDialog";
import { Message } from "element-ui";
export default {
  components: { hsdHeader, registerDialog },
  data() {
    return {
      loginId: 0,
      registerStep: 0,
      // refsl: ["resetDialog", "resetPasswordDialog", "resetSuccessDialog"],
      infoClass: false,
      showInputPassword: false, //reset password input
      resetSuccess: false, //reset Success
      resetDialog: false, //reset password
      topnavAddc: false,
      isAgreed: false, //同意条款
      isStudyInput: false,
      userMessage: {
        username: "",
        password: "",
        countryCode: "886",
        freeWeek: "0" //记住登录
      },
      teacherMessage: {
        username: "",
        password: ""
        // countryCode: "86",
        // freeWeek: "0"
      },
      isKeepLogin: false,
      resetPassword: {
        code: "",
        codeId: "",
        templateCode: "5",
        countryCode: "886",
        phoneNum: "",
        password: ""
      },
      errTips: null,
      surePassword: "",
      isShowLogin: true //是否登录
    };
  },
  watch: {
    isKeepLogin(newVal) {
      if (newVal) {
        this.userMessage.freeWeek = "1";
        this.teacherMessage.freeWeek = "1";
      } else {
        this.userMessage.freeWeek = "0";
        this.teacherMessage.freeWeek = "1";
      }
    }
  },
  created() {
    let token = window.localStorage.getItem("token");
    this.isShowLogin = token && typeof token == "string" ? false : true;
  },
  methods: {
    //进入注册页面
    goRegister() {},
    setRegisterStep(id) {
      this.registerStep = id;
    },
    //学生登录会员
    async toLogin() {
      this.checkCode(1, this.userMessage.username);
      try {
        const res = await getHsdUserLogin(this.userMessage);
        if (res && res.success == 1) {
          let userToken = {
            token: res.data.token,
            isStudent: true
          };
          window.localStorage.setItem("token", JSON.stringify(userToken));
          this.$router.push("/student");
        }
      } catch (err) {
        console.log(err);
      }
    },
    checkCode(id, val) {
      // const reg = new RegExp(/^[1][3-9]\d{9}$/); //大陆
      const reg_t = new RegExp(/^[0][9]\d{8}$/); //台湾
      let code = "";
      if (reg_t.test(val)) {
        code = "886";
      } else {
        code = "86";
      }
      if (id == 1) {
        this.userMessage.countryCode = code;
      } else {
        this.resetPassword.countryCode = code;
      }
    },
    //教师会员登录
    async toLoginTeacher() {
      try {
        const res = await getTeacherLogin(this.teacherMessage);
        if (res) {
          if (res.success == 1) {
            let userToken = {
              token: res.data.token,
              isTeacher: true
            };
            window.localStorage.setItem("token", JSON.stringify(userToken));
            this.$router.push("/teacher");
          }
        }
      } catch (err) {}
    },
    //获取短信验证码
    async getSmsCode() {
      this.checkCode(2, this.resetPassword.phoneNum);
      const { countryCode, templateCode, phoneNum } = this.resetPassword;
      //13872250190
      const res = await getHsdSmsCode({ countryCode, templateCode, phoneNum });
      if (res && res.code == 200 && res.success == 1) {
        this.resetPassword.codeId = res.data.code_id;
      }
    },
    //close dialog
    closeDialog(showId) {
      if (showId == "reset") {
        this.resetDialog = false;
      }
      if (showId == "showInputPassword") {
        this.showInputPassword = false;
      }
      if (showId == "resetSuccess") {
        this.resetSuccess = false;
      }
      this.topnavAddc = true; //导航栏样式
    },
    //发送手机号获取验证码
    sendPassword() {
      this.resetDialog = !this.resetDialog;
      this.showInputPassword = !this.showInputPassword;
      this.getSmsCode();
    },
    //chongzhimm Success
    async sendSmscode() {
      if (!this.resetPassword.password) {
        this.errTips = "請輸入設定密碼";
        return;
      }
      if (!this.surePassword) {
        this.errTips = "請再次輸入設定密碼";
        return;
      }
      if (this.resetPassword.password !== this.surePassword) {
        this.errTips = "密碼輸入不一致";
        return;
      }
      const res = await getHsdForgetPassword(this.resetPassword);
      if (res && res.code == 200 && res.success == 1) {
        this.showInputPassword = !this.showInputPassword;
        this.resetSuccess = !this.resetSuccess;
      }
    },
    //reset password
    forgetPassword() {
      this.resetDialog = true;
    }
  }
};
</script>
<style scoped>
@import "../../assets/css/main.css";
</style>